<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


export default {
  page: {
    title: "Standard Rides",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      riders: null,
      drivers: null,
      ride: null,
      showInput: null,
      newNumber: null,
      vehicles: null,
      flag: false,
      title: "Premium Rides",
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Premium Rides",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      filterStatus: ['reset', 'scheduled', 'Driver on the way', 'Driver at ride location', 'Ride started',
        'Ride ended', 'completed', 'expired'],
      filterData: 'reset',
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "rider_name", label: 'Rider Name', sortable: true },
        { key: "rider_number", label: 'Rider Number', sortable: true },
        { key: "driver_name", label: 'Driver Name', sortable: true },
        { key: "driver_number", label: 'Driver Number', sortable: true },
        { key: "createdAt", label: 'Booking Date', sortable: true },
        { key: "pick_up_at", label: 'Pick up', sortable: true },
        { key: "fare", sortable: true },
        { key: "status", label: 'Ride Status', sortable: true },
        { key: "canceledBy", label: 'Canceled By', sortable: true },
        { key: "action" }
      ],

      data_fields:{
        "Rider Name": "rider_name",
        "Rider Number": "rider_number",
        "Driver Name": "driver_name",
        "Driver Number": "driver_number",
        "Cancel status": "canceled",
        "Booking Date": "createdAt",
        "Car model": "car_model",
        "Car Name": "car_name",
        "Car Nmuber Plate": "car_plate_number",
        "Car Type": "car_type",
        "Car Year": "car_year",
        "PickUp Address": "pick_up_address_details",
        "Drop Off Address": "drop_off_address_details",
       
      },
    };
  },

  watch: {
    filterData: function (val) {
      this.$store.dispatch('hourlyRides/setRequiredStandardRides', val)
    },

    rows: function (val){
      this.totalRows = val
    }
  },

  computed: {
    /**
     * Total no. of records
     */
    standardRides() {
      return this.$store.getters['hourlyRides/standardRidesGetter']
    },

    rows() {
      return this.standardRides.length;
    }

  },
  mounted() {

    // Set the initial number of items
    this.$store.dispatch('hourlyRides/getStandardRides')
    this.totalRows = this.standardRides.length;

  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    pushRouter(route) {
      this.$router.push(route)
    },

    findRider(id) {
      if (id) {
        let myRider = this.riders.find(obj => obj._id === id)
        return myRider.phone_number
      }

    },

    findDriver(id) {
      if (id) {
        let myDriver = this.drivers.find(obj => obj._id === id)
        return myDriver.phone_number
      }
    },

    convertDate(date) {
      if (date) {
        let newDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
        return newDate
      }
    },

    convertTime(time) {
      if (time) {
        let newTime = new Date(time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        return newTime
      }
    },

    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 43) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    cancelStatus(status) {
      if (status === true) {
        return 'Yes'
      }
      else {
        return 'No'
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h4 class="card-title font-size-20"><strong> Premium Rides</strong></h4>
              <download-excel :data="standardRides" :fields="data_fields" :name="'Hourly Rides'">
                <b-button variant="primary">Export Premium Rides</b-button>
              </download-excel>
            </div>
            <div class="row mt-4">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Filter&nbsp;
                    <b-form-select v-model="filterData" size="sm" :options="filterStatus"></b-form-select>
                  </label>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-2 col-lg-3 col-xl-3 d-inline-flex" style="font-weight: 600;margin-top: 4px;">
                No. of Premium Rides: {{ totalRows }}
              </div>
              <!-- Search -->
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div v-if="standardRides.length == 0 && flag === true">
              <p>No Records Found</p>
            </div>
            <div class="table-responsive mb-0" v-else-if="standardRides.length !== 0">
              <b-table :items="standardRides" :fields="fields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <!--                <template v-slot:cell(rider_id)="rider">-->
                <!--                      {{findRider(rider.value)}}-->
                <!--                </template>-->




                <template v-slot:cell(status)="row">
                  <div class="badge font-size-14"
                    :class="{ 'badge-danger': `${row.value}` === 'expired' || `${row.value}` === 'canceled', 'badge-success': `${row.value}` === 'completed', 'badge-warning': `${row.value}` === 'pending', 'badge-secondary': `${row.value}` === 'scheduled', 'badge-primary': `${row.value}` === 'Driver on the way', 'badge-info': `${row.value}` === 'Driver at ride location', 'badge-dark': `${row.value}` === 'Ride started' || `${row.value}` === 'Ride ended' }">
                    {{ row.value }}
                  </div>
                </template>


                <template v-slot:cell(createdAt)="bookDate">
                  {{ convertDate(bookDate.value) }}
                </template>

                <template v-slot:cell(pick_up_at)="pickUp">
                  {{ convertDate(pickUp.value) }} {{ convertTime(pickUp.value) }}
                </template>

                <template v-slot:cell(action)="{ item }">
                  <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                    title="Details" @click="$router.push({ path: `/rides/standardDetails/${item._id}` })">
                    <i class="ri-folder-shared-fill font-size-24"></i>
                  </a>
                </template>

              </b-table>
              <div :style="{ display: 'none' }">
                {{ flag=true }}
              </div>

            </div>
            <div class="row " v-else>
              <div class="col text-center">
                <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
              </div>

            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>


    <!-- <div class="row " v-show="standardRides.length === 0">
      <div class="col text-center">
        <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
      </div>
    </div> -->

  </Layout>
</template>